import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button, Form, Row, Col, notification, Popconfirm } from 'antd';
import DataTableMP from '../tables/DataTableMP';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { DownloadOutlined } from '@ant-design/icons';
import ActionButton from '../base/ActionButton';
import { UserContext } from '../../App';
import moment from 'moment';

const { TextArea } = Input;

const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
};

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const OrderForm = ({
  data,
  onSubmit,
  createInvoice,
  changeOrderItemStatus,
  token,
  type,
  triggerReload,
  setWorking,
}) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [products, setProducts] = useState(data.order);
  const [mail, setMail] = useState(false);
  const [bex, setBex] = useState(false);
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };

  let nf = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    setProducts(data.order);
  }, [data]);

  const onFinish = async (formData) => {
    onSubmit(formData);
  };

  const discount = (percent, record) => {
    record.discountPercentage = percent;
    const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);
    setProducts(newProducts);
  };

  const columnKeys = ['code', 'name', 'quantity', 'price'];

  let adresa;
  if (data && data.deliveryDetails && data.deliveryDetails.address) {
    adresa = `${data.user.address}, ${data.user.zip} ${data.user.city}`;
  } else if (data.user && data.user.place && data.user.place.name) {
    adresa = `${data.user.street.name} ${data.user.streetNumber}, ${data.user.zip} ${data.user.place.name}`;
  } else if (data.user.legalData && data.user.legalData.address) {
    adresa = `${data.user.legalData.address}, ${data.user.legalData.zip} ${data.user.legalData.city}`;
  } else {
    adresa = '';
  }

  let adresaIsporuke;
  if (data && data.deliveryDetails && data.deliveryDetails.address) {
    adresaIsporuke = `${data.deliveryDetails.address}, ${data.deliveryDetails.zip} ${data.deliveryDetails.city}`;
  } else if (data.user && data.user.deliveryAddressSame && data.user.place && data.user.place.name) {
    adresaIsporuke = `${data.user.street.name} ${data.user.streetNumber}, ${data.user.zip} ${data.user.place.name}`;
  } else if (data.user && !data.user.deliveryAddressSame && data.user.place && data.user.place.name) {
    adresaIsporuke = `${data.user.streetDelivery.name} ${data.user.streetNumberDelivery}, ${data.user.zipDelivery} ${data.user.placeDelivery.name}`;
  } else if (data.user.legalData && data.user.legalData.address) {
    adresaIsporuke = `${data.user.legalData.address}, ${data.user.legalData.zip} ${data.user.legalData.city}`;
  } else {
    adresaIsporuke = '';
  }

  let telefon;
  if (data.user.phone1) {
    telefon = data.user.phone1;
  } else if (data.user.phone && data.user.phone.length > 0) {
    telefon = data.user.phone[0];
  }

  const accDiscount = data.accDiscount ? +data.accDiscount : 0;

  const noDisTotalPrice = data.order
    .filter((item) => item.superPrice || item.isSpecialOffer)
    .reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0);

  const order_details = {
    kupac: {
      ime:
        type === 'vp'
          ? data.user.companyName
          : data.user.fullName
          ? data.user.fullName
          : `${data.user.firstName} ${data.user.lastName}`,
      adresa: adresa,
      adresaIsporuke: adresaIsporuke,
      email: data.user.email,
      telefon: telefon,
      vremePorudzbine: new Date(data.createdAt).toLocaleDateString('sr-Latn-RS', dateOptions),
      statusKupca: data.user.status === 'ACTIVE' ? `AKTIVAN` : `ČEKA AKTIVACIJU`,
      odradjeno: '30.04.2021',
      napomena: data.message,
      kontaktOsoba: data?.user?.fullName ? data?.user?.fullName : `${data?.user?.firstName} ${data?.user?.lastName}`,
    },
    total: {
      ukupno: data.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0),
      prevoz: data.deliveryPrice,
      pdv:
        (data.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) +
          +data.deliveryPrice) *
        0.2,
      initialDiscount: data.initialDiscount
        ? data.order
            .filter((item) => !item.superPrice && !item.isSpecialOffer)
            .reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) * 0.2
        : 0,
      initialDiscountTotal: data.initialDiscount
        ? data.order
            .filter((item) => !item.superPrice && !item.isSpecialOffer)
            .reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) *
            0.8 +
          noDisTotalPrice
        : 0,
      zaUplatu:
        data.order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0) +
        +data.deliveryPrice -
        accDiscount,
      avansnaUplata: data.advancePayment ? 'DA' : 'NE',
    },
  };
  let discountPercentage;
  if (data?.discountPercentage) {
    discountPercentage = data.discountPercentage;
  } else {
    if (data?.accDiscount) {
      if (Number(data.accDiscount) >= 150 && Number(data.accDiscount) <= 300) {
        discountPercentage = 1;
      } else if (Number(data.accDiscount) >= 600 && Number(data.accDiscount) <= 1200) {
        discountPercentage = 2;
      } else if (Number(data.accDiscount) >= 1800 && Number(data.accDiscount) <= 3600) {
        discountPercentage = 3;
      } else if (Number(data.accDiscount) > 3600) {
        discountPercentage = 4;
      } else {
        discountPercentage = 0;
      }
    } else {
      discountPercentage = 0;
    }
  }

  const totalMinusInitialDiscount = order_details.total.zaUplatu - order_details.total.initialDiscount;
  const handleMail = async () => {
    try {
      const payload = {
        orderId: data._id,
      };
      await axios.post(`${SERVER_URL}/order-sent-mail`, payload, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      notification.success({
        message: 'Mejl uspešno poslat.',
        placement: 'bottomRight',
      });

      setMail(true);
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Greška pri slanju mejla.',
        placement: 'bottomRight',
      });
    }
  };

  const handleBexMail = async () => {
    try {
      let shipmentCategory;
      const weight = products.reduce((total, current) => {
        return +total + +current.weight;
      }, 0);
      if (weight >= 0 && weight <= 0.5) shipmentCategory = 1;
      else if (weight >= 0.51 && weight <= 1) shipmentCategory = 2;
      else if (weight >= 1.01 && weight <= 2) shipmentCategory = 3;
      else if (weight >= 2.01 && weight <= 5) shipmentCategory = 4;
      else if (weight >= 5.01 && weight <= 10) shipmentCategory = 5;
      else if (weight >= 10.01 && weight <= 20) shipmentCategory = 7;
      else if (weight >= 20.01 && weight <= 30) shipmentCategory = 8;
      else if (weight >= 30.01 && weight <= 50) shipmentCategory = 9;
      else if (weight >= 50.01) shipmentCategory = 31;

      let phone;
      let totalPrice = nf.format(
        (type === 'mp' || data.initialDiscount
          ? totalMinusInitialDiscount
          : order_details.total.zaUplatu + +order_details.total.pdv
        ).toFixed(2),
      );
      if (data.user.phone.length === 1) phone = data.user.phone[0];
      else if (data.user.phone.length === 2) phone = data.user.phone.join(';');
      const shipment = {};
      shipment.shipmentId = 0;
      shipment.serviceSpeed = 1;
      shipment.shipmentType = 1;
      shipment.shipmentCategory = shipmentCategory;
      shipment.shipmentWeight = shipmentCategory === 31 || shipmentCategory === 32 ? weight : 0;
      shipment.totalPackages = products.length;
      shipment.invoiceAmount = 0;
      shipment.shipmentContents = 12;
      shipment.commentPublic = data.message.trim();
      shipment.commentPrivate = '';
      shipment.personalDelivery = false;
      shipment.returnSignedInvoices = false;
      shipment.returnSignedConfirmation = false;
      shipment.returnPackage = false;
      shipment.payType = data.payedWith === 'payByCard' ? 6 : 2;
      shipment.insuranceAmount = 0;
      shipment.payToSender = totalPrice;
      shipment.payToSenderViaAccount = data.payedWith === 'payByCard' ? true : false;
      shipment.sendersAccountNumber = data.payedWith === 'payByCard' ? '170-003003962300581' : '';
      shipment.bankTransferComment = '';

      const task1 = {};
      task1.type = 1;
      task1.nameType = 2;
      task1.name1 = 'HIMTEX COMPANY DOO'; // 'INTERNET PRODAJA GUMA D.O.O.';
      task1.name2 = '';
      task1.taxId = '102947068'; // '107219509';
      task1.adressType = 3;
      task1.municipalites = 137;
      task1.street = '36113';
      task1.place = '4680';
      task1.houseNumber = 94;
      task1.apartment = '';
      task1.contactPerson = data.user.firstName + ' ' + data.user.lastName; // 'Marija Vujačić';
      task1.phone = phone; // '0653447449';
      task1.date = moment(new Date()).format('YYYY-MM-DD');
      task1.timeFrom = '07:55';
      task1.timeTo = '14:55';
      task1.preNotification = 0;
      task1.comment = '';

      const noAddress = ['bb', 'BB', 'Bb', 'bB'];

      const task2 = {};
      task2.type = 2;
      task2.nameType = 1;
      task2.name1 = data.user.lastName;
      task2.name2 = data.user.firstName;
      task2.taxId = data.user?.taxId || '';
      task2.adressType = 1;
      task2.municipalites = data.user?.municipality;
      task2.street = data.user?.street?.name;
      task2.place = data.user?.place?.name;
      task2.houseNumber = noAddress.includes(data.user.streetNumber) ? 0 : data.user.streetNumber;
      task2.apartment = '';
      task2.contactPerson = data.user.lastName + ' ' + data.user.firstName;
      task2.phone = phone;
      task2.date = moment(new Date()).format('YYYY-MM-DD');
      task2.timeFrom = '07:55';
      task2.timeTo = '14:55';
      task2.preNotification = 0;
      task2.comment = '';

      shipment.tasks = [task1, task2];
      const sendArrayForBex = {
        shipmentslist: [shipment],
        lang: 2,
        cust: 101,
      };
      const bexResponse = await axios.post(
        `${SERVER_URL}/post-shipment`,
        {
          data: JSON.stringify(sendArrayForBex),
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (
        bexResponse.data['reqstate'] == true &&
        Array.isArray(bexResponse.data['shipmentsResultList']) &&
        bexResponse.data['shipmentsResultList'][0].state == true
      ) {
        const returnResult = bexResponse.data['shipmentsResultList'][0];
        const shipmentId = returnResult['shipmentId'];
        await axios.put(
          `${SERVER_URL}/orders/${data._id}`,
          { shipmentId },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        notification.success({
          message: 'Mejl uspešno poslat Bex-u',
          placement: 'bottomRight',
        });
        setBex(true);
      } else {
        let errorMessage = bexResponse.data['shipmentsResultList'][0].err;
        await axios.put(
          `${SERVER_URL}/orders/${data._id}`,
          { errorMessage },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        notification.error({
          message: 'Greška pri slanju mejla Bex-u',
          placement: 'bottomRight',
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Greška pri slanju mejla Bex-u',
        placement: 'bottomRight',
      });
    }
  };

  const increment = (record) => {
    record.quantity++;
    const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);
    setProducts(newProducts);
  };

  const decrement = (record) => {
    if (record.quantity !== 1) {
      record.quantity--;
      const newProducts = products.map((x) => products.find(({ id }) => id === record._id) || x);

      setProducts(newProducts);
    }
    return false;
  };

  const removeProduct = (record) => {
    const newProducts = products.filter((product) => product._id !== record);
    setProducts(newProducts);
  };

  const updateOrder = async (props) => {
    let status;
    if (data.status === 'On hold') {
      status = 'Processed';
    } else if (data.status === 'Processed') {
      status = 'On hold';
    }
    try {
      await axios.put(
        `${SERVER_URL}/orders/${data._id}`,
        { status },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      notification.success({
        message: 'Porudžbina je izmenjena!',
        placement: 'topRight',
        duration: 3,
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: 'Došlo je do greške!',
        placement: 'topRight',
        duration: 3,
      });
    }
  };

  const createOxW = async () => {
    try {
      setWorking(true);

      await axios.post(
        `${SERVER_URL}/orders/OxW-document/${data._id}`,
        { type },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setWorking(false);

      notification.success({
        message: 'OxW dokument je kreiran!',
        placement: 'topRight',
        duration: 3,
      });
    } catch (error) {
      setWorking(false);
      console.error('error', error.response.data.message);
      notification.error({
        message: error.response.data.message,
        placement: 'topRight',
        duration: 3,
      });
    }
    triggerReload((prev) => !prev);
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  const deleteOrder = async () => {
    let path;
    if (data.user.legalType === 'corporate') {
      if (data.status === 'On hold') {
        path = 'new-orders-vp';
      } else if (data.status === 'Processed') {
        path = 'finished-orders-vp';
      }
    } else if (data.user.legalType === 'private') {
      if (data.status === 'On hold') {
        path = 'new-orders-mp';
      } else if (data.status === 'Processed') {
        path = 'finished-orders-mp';
      }
    }
    try {
      await axios.delete(`${SERVER_URL}/orders/${data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      history.push(`/admin/${path}`);
    } catch (error) {
      console.log('error', error);
      notification.error({
        message: 'Došlo je do greške!',
        placement: 'topRight',
        duration: 3,
      });
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body' style={{ background: 'whitesmoke' }}>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={onFinish}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={24}>
              <div className='panel-body-wrapper'>
                <div className='panel-body'>
                  <DataTableMP
                    data={products}
                    columnKeys={columnKeys}
                    title={'OrderTable'}
                    increment={increment}
                    decrement={decrement}
                    removeProduct={removeProduct}
                    discount={discount}
                    changeOrderItemStatus={changeOrderItemStatus}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} md={24}>
              <div className='orders-container'>
                <div className='orders-container__left'>
                  <div className='buyer-details'>
                    <div className='buyer-row'>
                      <div className='buyer-row__left'>Kupac:</div>
                      <div className='buyer-row__right'>{order_details.kupac.ime}</div>
                    </div>
                    {/* <div className='buyer-row'>
                      <div className='buyer-row__left'>Adresa:</div>
                      <div className='buyer-row__right'>{order_details.kupac.adresa}</div>
                    </div> */}
                    <div className='buyer-row buyer-row--isporuka'>
                      <div className='buyer-row__left'>Adresa Isporuke:</div>
                      <div className='buyer-row__right'>{order_details.kupac.adresaIsporuke}</div>
                    </div>
                    <div className='buyer-row'>
                      <div className='buyer-row__left'>Email:</div>
                      <div className='buyer-row__right'>{order_details.kupac.email}</div>
                    </div>
                    <div className='buyer-row'>
                      <div className='buyer-row__left'>Telefon:</div>
                      <div className='buyer-row__right'>{order_details.kupac.telefon}</div>
                    </div>
                    <div style={{ borderBottom: '1px solid lightgray' }} className='buyer-row'>
                      <div className='buyer-row__left'>Kontakt osoba:</div>
                      <div className='buyer-row__right'>{order_details.kupac.kontaktOsoba}</div>
                    </div>
                    <div
                      style={{ borderBottom: '1px solid lightgray', paddingBottom: '10px' }}
                      className='order-status'
                    >
                      Status kupca: <span className='hmtxOrange'>{order_details.kupac.statusKupca}</span>
                    </div>
                    <div className='order-time'>Vreme porudzbine: {order_details.kupac.vremePorudzbine}</div>
                    <div>Naručeno sa: {data?.orderedVia?.toUpperCase()}</div>
                    <div className='buyer-row'>Komercijalista: {data?.user?.commercialist}</div>
                  </div>

                  <div className='order-info-right'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {data.user.legalType === 'corporate' && (
                        <div>
                          <p>Naziv firme: {data.user.companyName}</p>
                          <p>PIB: {data.user.taxId}</p>
                          <p>Matični br.: {data.user.idNumber}</p>
                        </div>
                      )}

                      <div style={{ textAlign: 'center' }}>
                        <p style={{ color: 'black', fontWeight: 'bold' }}>Finansije:</p>
                      </div>
                    </div>

                    <div className='order-info-right-bottom order-info'>
                      <div className='order-references'>
                        <div className='references-top'>
                          <div>
                            Referenca
                            <Form.Item name='reference'>
                              <Input readOnly={currentuser.data.role !== 'admin'} style={{ borderRadius: '4px' }} />
                            </Form.Item>
                          </div>
                          <div className='order-info--top'>
                            <a
                              href={`${SERVER_URL}/download-order-file/${data._id}`}
                              download
                              style={{ marginBottom: '10px' }}
                            >
                              <Button
                                disabled={currentuser.data.role !== 'admin'}
                                type='primary'
                                shape='round'
                                icon={<DownloadOutlined />}
                              >
                                TXT fajl
                              </Button>
                            </a>
                            <Button
                              disabled={currentuser.data.role !== 'admin'}
                              type='primary'
                              htmlType='button'
                              onClick={() => {
                                createOxW();
                              }}
                            >
                              Napravi {type === 'vp' ? '01W' : '02W'}
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className='order-info--bottom'>
                        Fakture
                        <Form.Item name='invoice1'>
                          <Input
                            readOnly={currentuser.data.role !== 'admin'}
                            placeholder='# Faktura 1'
                            style={{ borderRadius: '4px' }}
                          />
                        </Form.Item>
                        <Form.Item name='invoice2'>
                          <Input
                            readOnly={currentuser.data.role !== 'admin'}
                            placeholder='# Faktura 2'
                            style={{ borderRadius: '4px' }}
                          />
                        </Form.Item>
                        <Form.Item name='invoice3'>
                          <Input
                            readOnly={currentuser.data.role !== 'admin'}
                            placeholder='# Faktura 3'
                            style={{ borderRadius: '4px' }}
                          />
                        </Form.Item>
                      </div>

                      <Form.Item style={{ flex: '2', marginBottom: '0' }} name='message' label='Napomena'>
                        <TextArea readOnly={currentuser.data.role !== 'admin'} rows={7} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className='orders-container__right'>
                  <div className='indRow'>
                    <div className='indRow--left'>Ukupno roba:</div>
                    <div>{nf.format(order_details.total.ukupno.toFixed(2))}</div>
                  </div>
                  {type === 'mp' &&
                  data.initialDiscount &&
                  !data.order.every((item) => item.superPrice || item.isSpecialOffer) ? (
                    <div className='indRow' style={{ color: '#f95e00' }}>
                      <div className='indRow--left'>Prva kupovina 20%:</div>
                      <div>{nf.format(order_details.total.initialDiscountTotal)}</div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='indRow'>
                    <div className='indRow--left'>Prevoz:</div>
                    <div>{nf.format(order_details.total.prevoz)}</div>
                  </div>
                  {type === 'vp' ? (
                    <div className='indRow'>
                      <div className='indRow--left' style={{ color: 'red' }}>
                        Nagrada za samoporučivanje:
                      </div>
                      <div style={{ color: 'red' }}>
                        {data.accDiscount ? '- ' + nf.format(data.accDiscount) : '0.00'}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {type === 'vp' ? (
                    <div className='indRow'>
                      <div className='indRow--left' style={{ color: 'red' }}>
                        Korišćeni procenat za nagradu:{' '}
                      </div>
                      <div style={{ color: 'red' }}>{discountPercentage}%</div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='indRow'>
                    <div className='indRow--left'>Ukupno:</div>
                    <div>
                      {nf.format(
                        (type === 'mp' || data.initialDiscount
                          ? totalMinusInitialDiscount
                          : order_details.total.zaUplatu
                        ).toFixed(2),
                      )}
                    </div>
                  </div>
                  <div className='indRow'>
                    <div className='indRow--left'>{type === 'mp' ? 'Od toga PDV:' : 'PDV:'}</div>
                    <div>
                      {nf.format(
                        type === 'mp' || data.initialDiscount
                          ? totalMinusInitialDiscount - totalMinusInitialDiscount / 1.2
                          : order_details.total.pdv.toFixed(2),
                      )}
                    </div>
                  </div>
                  <div className='indRow'>
                    <div className='indRow--left'>Za uplatu:</div>
                    <div>
                      {nf.format(
                        (type === 'mp' || data.initialDiscount
                          ? totalMinusInitialDiscount
                          : order_details.total.zaUplatu + +order_details.total.pdv
                        ).toFixed(2),
                      )}
                    </div>
                  </div>
                  <div className='indRow'>
                    <div className='indRow--left'>Avansna uplata:</div>
                    <div>{order_details.total.avansnaUplata}</div>
                  </div>
                  <div className='indRow'>
                    <div className='indRow--left'>Način plaćanja:</div>
                    <div>{data.payedWith ? (data.payedWith === 'cashOnDelivery' ? 'Pouzećem' : 'Karticom') : '~'}</div>
                  </div>
                </div>
              </div>
            </Col>
            <div>
              <ActionButton
                disable={currentuser.data.role !== 'admin'}
                text='OZNACI DA NIJE ODRAĐENO'
                icon={<DownloadOutlined />}
                bgColor='#f84804'
                updateOrder={updateOrder}
                status={data.status}
              />
              <ActionButton
                disable={currentuser.data.role !== 'admin'}
                text='SAČUVAJ'
                icon={<DownloadOutlined />}
                bgColor='#666'
              />
              <Popconfirm
                disabled={currentuser.data.role !== 'admin'}
                title='Da li ste sigurni da želite da obrišete ovu porudžbinu?'
                onConfirm={() => deleteOrder()}
              >
                <Button
                  icon={<DownloadOutlined />}
                  style={{
                    backgroundColor: 'red',
                  }}
                  className='actionBtn'
                >
                  OBRIŠI
                </Button>
              </Popconfirm>
              {/* <ActionButton text='OBRIŠI' icon={<DownloadOutlined />} bgColor='red' deleteOrder={deleteOrder} /> */}
              {data.user.role === 'retail user' && (
                <>
                  <Button
                    disabled={data.orderMailDelivered || mail}
                    onClick={handleMail}
                    className='actionBtn'
                    style={{ background: 'rgb(3, 145, 252)', padding: '0px 20px' }}
                  >
                    MAIL {data.orderMailDelivered || mail ? '- POSLATO' : '- POŠALJI'}
                  </Button>
                  {/* TO DO: */}
                  {/* <Button
                    disabled={bex}
                    onClick={handleBexMail}
                    className='actionBtn'
                    style={{ background: 'green', padding: '0px 50px' }}
                  >
                    BEX {bex ? '- POSLATO' : '- POŠALJI'}
                  </Button> */}
                </>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
