import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { ROUTES_VP } from '../../configVP';
import { ROUTES_ADMIN } from '../../configAdmin/index';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          {/* <img src='/logoW.png' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' /> */}
          <img src='/himtex-logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {props.topBar === 'mp' &&
          ROUTES.map((route) => (
            <SubMenu
              className='submenu'
              key={route.label.toLowerCase()}
              title={
                isCollapsed ? (
                  <route.icon />
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <i className={`navMenuIcon ${route.icon}`}></i>
                    <Link to={route.children[0].path}>{route.label}</Link>
                  </span>
                )
              }
            ></SubMenu>
          ))}
        {props.topBar === 'vp' &&
          ROUTES_VP.map((route) => (
            <SubMenu
              className='submenu'
              key={route.label.toLowerCase()}
              title={
                isCollapsed ? (
                  <route.icon />
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <i className={`navMenuIcon ${route.icon}`}></i>
                    <Link to={route.children[0].path}>{route.label}</Link>
                  </span>
                )
              }
            ></SubMenu>
          ))}
        {props.topBar === 'administracija' &&
          ROUTES_ADMIN.map((route) => (
            <SubMenu
              className='submenu'
              key={route.label.toLowerCase()}
              title={
                isCollapsed ? (
                  <route.icon />
                ) : (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <i className={`navMenuIcon ${route.icon}`}></i>
                    <Link to={route.children[0].path}>{route.label}</Link>
                  </span>
                )
              }
            >
              {route.label === 'HOME' &&
                route.children
                  .filter((item) => item.showInMenu)
                  .map((item) => (
                    <Menu.Item key={item.path}>
                      <Link to={item.path} title={item.label}>
                        {item.label}
                      </Link>
                    </Menu.Item>
                  ))}
            </SubMenu>
          ))}
      </Menu>
      <div className='sider-profile-controls'>
        {/* <Link
          to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </Link> */}

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
      </div>
    </Sider>
  );
};
export default NavMenu;
