import React from 'react';
import { Button } from 'antd';

const ActionButton = ({ disable, text, icon, bgColor, updateOrder, status, deleteOrder }) => {
  return (
    <Button
      disabled={disable}
      onClick={() => (text === 'OBRIŠI' ? deleteOrder() : text.includes('ODRAĐENO') ? updateOrder('status') : '')}
      icon={icon}
      style={{
        backgroundColor: bgColor,
      }}
      className='actionBtn'
      htmlType={text === 'SAČUVAJ' ? 'submit' : 'button'}
    >
      {status === 'On hold' ? 'ODRAĐENO' : status === 'Processed' ? 'OZNAČI DA NIJE ODRAĐENO' : text}
    </Button>
  );
};

export default ActionButton;
