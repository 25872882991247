import Slider from '../pages/slider/Slider';
import EditSlider from '../pages/slider/EditSlider';
import SliderGallery from '../pages/slider/SliderGallery';
import Contacts from '../pages/contacts/Contacts';
import EditContact from '../pages/contacts/EditContact';
import Reviews from '../pages/reviews/Reviews';
import EditReview from '../pages/reviews/EditReview';
import Articles from '../pages/home/Articles';
import Banners from '../pages/home/Banners';
import EditBanner from '../pages/home/EditBanner';
import BannerGallery from '../pages/home/BannerGallery';

const routes = [
  {
    label: 'SLAJDER',
    icon: 'ri-window-fill',
    children: [
      {
        label: 'SLAJDER',
        path: '/admin/slider',
        component: Slider,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Ažuriraj Slajder',
        path: '/admin/edit-slider/:id',
        component: EditSlider,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Novi Slajder',
        path: '/admin/new-slider',
        component: EditSlider,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Galerija',
        path: '/admin/slider-gallery',
        component: SliderGallery,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'KONTAKTI',
    icon: 'ri-phone-fill',
    children: [
      {
        label: 'KONTAKTI',
        path: '/admin/contacts',
        component: Contacts,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi Kontakt',
        path: '/admin/new-contact',
        component: EditContact,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Novi Kontakt',
        path: '/admin/edit-contact/:id',
        component: EditContact,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'RECENZIJE',
    icon: 'ri-bookmark-3-line',
    children: [
      {
        label: 'RECENZIJE',
        path: '/admin/reviews',
        component: Reviews,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit review',
        path: '/admin/edit-review/:id',
        component: EditReview,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'HOME',
    icon: 'ri-home-fill',
    children: [
      {
        label: 'Artikli',
        path: '/admin/home/articles',
        component: Articles,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Baneri',
        path: '/admin/home/banners',
        component: Banners,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi Baneri',
        path: '/admin/home/new-banner',
        component: EditBanner,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Banera',
        path: '/admin/home/edit-banner/:id',
        component: EditBanner,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Baner galerija',
        path: '/admin/banner-gallery',
        component: BannerGallery,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
];

export default routes;
