import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import { UserContext } from '../../App';

const PostsTable = ({ data, deleteHandler, viewPath, type }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  let columns;
  if (type === 'corporate') {
    columns = [
      {
        key: 'orderNumber',
        title: 'Broj por.',
        dataIndex: 'orderNumber',
        ...getColumnSearchProps('orderNumber'),
      },
      {
        key: 'reference',
        title: 'Referenca',
        dataIndex: 'reference',
        ...getColumnSearchProps('reference'),
      },
      {
        key: 'invoiceRef',
        title: 'Faktura',
        dataIndex: 'invoiceRef',
        ...getColumnSearchProps('invoiceRef'),
      },
      {
        key: ['user', '_id'],
        title: 'Kupac',
        dataIndex: ['user', 'companyName'],
        ...getColumnSearchProps(['user', 'companyName']),
      },
      {
        key: ['user', '_id'],
        title: 'Email',
        dataIndex: ['user', 'email'],
        ...getColumnSearchProps(['user', 'email']),
      },
      {
        key: 'price',
        title: 'Iznos',
        dataIndex: 'price',
      },
      {
        key: 'createdAt',
        title: 'Datum i vreme',
        dataIndex: 'newDate',
      },
    ];
  } else {
    columns = [
      {
        key: 'orderNumber',
        title: 'Broj por.',
        dataIndex: 'orderNumber',
        ...getColumnSearchProps('orderNumber'),
      },
      {
        key: 'reference',
        title: 'Referenca',
        dataIndex: 'reference',
        ...getColumnSearchProps('reference'),
      },
      {
        key: 'invoice1',
        title: 'Faktura',
        dataIndex: 'invoice1',
        ...getColumnSearchProps('invoice1'),
      },
      {
        key: ['user', '_id'],
        title: 'Kupac',
        dataIndex: ['user', 'firstName'],
        ...getColumnSearchProps(['user', 'firstName']),
      },
      {
        key: ['user', '_id'],
        title: 'Email',
        dataIndex: ['user', 'email'],
        ...getColumnSearchProps(['user', 'email']),
      },
      {
        key: 'price',
        title: 'Iznos',
        dataIndex: 'price',
      },
      {
        key: 'createdAt',
        title: 'Datum i vreme',
        dataIndex: 'newDate',
      },
    ];
  }

  columns.push({
    title: 'Akcija',
    render: (text, record) => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={viewPath + record._id}>
            <EditOutlined
              className='icon-unlock'
              // title={`Edit ${title.toLowerCase()}`}
              title={`Izmeni narudžbu`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <EditFilled
              className='icon-lock'
              // title={`Edit ${title.toLowerCase()}`}
              title={`Izmeni narudžbu`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>
      </div>
    ),
  });

  if (user.data.role === 'admin') {
    columns.push({
      title: 'Brisanje',
      render: (value, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px' }} className='lock'>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              // title={`This will delete ${title.toLowerCase()}`}
              title={`Ovo će izbrisati narudžbu`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='U redu'
              cancelText='Poništi'
              cancelButtonProps={{ className: 'b2b-secondary' }}
              okButtonProps={{ className: 'b2b-primary' }}
            >
              <DeleteOutlined
                className='icon-unlock'
                // title={`Delete ${title.toLowerCase()}`}
                title={`Obriši narudžbu`}
              />
              <DeleteFilled
                className='icon-lock'
                // title={`Delete ${title.toLowerCase()}`}
                title={`Obriši narudžbu`}
              />
            </Popconfirm>
          </div>
        </div>
      ),
    });
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default PostsTable;
