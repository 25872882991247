import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import { ROUTES_VP } from '../../configVP';
import { ROUTES_ADMIN } from '../../configAdmin';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';

const Routes = (props) => {
  const user = useContext(UserContext);

  return (
    <Switch>
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin'
        component={Home}
        allowed={['admin', 'user', 'admin', 'commercialist']}
      />
      {/* routes from routeConfig file */}
      {props.topBar === 'mp' &&
        ROUTES.map((route) =>
          route.children.map((item, i) => (
            <ProtectedRoute
              topBar={item.value}
              exact
              user={user.data}
              key={`R_${i}`}
              path={item.path}
              component={item.component}
              allowed={item.allowed}
            />
          )),
        )}
      {props.topBar === 'vp' &&
        ROUTES_VP.map((route) =>
          route.children.map((item, i) => (
            <ProtectedRoute
              topBar={item.value}
              exact
              user={user.data}
              key={`R_${i}`}
              path={item.path}
              component={item.component}
              allowed={item.allowed}
            />
          )),
        )}
      {props.topBar === 'administracija' &&
        ROUTES_ADMIN.map((route) =>
          route.children.map((item, i) => (
            <ProtectedRoute
              topBar={item.value}
              exact
              user={user.data}
              key={`R_${i}`}
              path={item.path}
              component={item.component}
              allowed={item.allowed}
            />
          )),
        )}
      <Route exact path='/login' render={() => (user && user.isLoggedIn ? <Redirect to='/admin' /> : <Login />)} />
      <Route render={() => <Redirect to='/admin' />} />
    </Switch>
  );
};

export default Routes;
